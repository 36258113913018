import { Children, FC, Suspense, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Breadcrumbs, Drawer, IconButton, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import { useTools } from '@/contexts/ToolsContext';
import { isMobile } from 'react-device-detect';
import MenuIcon from '@mui/icons-material/Menu';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import iconLogo from '@/images/iconLogo.png';
import LightLogo from '@/images/LightLogo.png';
import DarkLogo from '@/images/DarkLogo.png';
import { useAppSelector } from '@/redux/hooks';
import ErrorScreen from '@/screens/error/ErrorScreen';
import AdminSidebar from '@/Layout/Sidebar/AdminSidebar';
import Holding from '@/screens/layouts/Holding';

const Admin: FC = () => {
    const theme = useTheme();
    const isAdmin = useAppSelector((state) => state.user.data?.is_admin);
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { classes } = useStyles({ isMobile, smallScreen });
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const { tools, breadcrumbs, modal } = useTools();
    const toggleDrawer = () => {
        setDrawerOpen((prevState) => !prevState);
    };

    if (!isAdmin) {
        return <ErrorScreen message={'Invalid permissions.'} />;
    }

    return (
        <div className={classes.root}>
            <div className={classes.appBar}>
                {(isMobile || smallScreen) && (
                    <>
                        <IconButton sx={{ mr: 1 }} onClick={toggleDrawer}>
                            <MenuIcon />
                        </IconButton>
                        <Link to={'/admin'}>
                            <img
                                style={{ marginRight: theme.spacing(2) }}
                                width={32}
                                height={32}
                                src={iconLogo}
                                alt={'Logo'}
                            />
                        </Link>
                    </>
                )}
                {!isMobile && !smallScreen && (
                    <>
                        <Link to={'/admin'}>
                            <Stack spacing={2} direction={'row'}>
                                <img width={32} height={32} src={iconLogo} alt={'Logo'} />
                                <img
                                    height={32}
                                    width={157}
                                    src={theme.palette.mode === 'dark' ? LightLogo : DarkLogo}
                                    alt={'Logo'}
                                />
                            </Stack>
                        </Link>
                    </>
                )}
                <div className={classes.breadcrumbs}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize={'small'} />}>
                        {smallScreen
                            ? Children.toArray(breadcrumbs)[Children.toArray(breadcrumbs).length - 1]
                            : breadcrumbs}
                    </Breadcrumbs>
                </div>
                <div className={classes.tools}>{tools}</div>
            </div>
            <div className={classes.main}>
                {isMobile || smallScreen ? (
                    <Drawer
                        PaperProps={{ sx: { borderTopRightRadius: (theme) => theme.spacing(1) } }}
                        open={drawerOpen}
                        onClose={toggleDrawer}
                        ModalProps={{ keepMounted: true }}
                        transitionDuration={theme.transitions.duration.complex}
                    >
                        <AdminSidebar {...{ toggleDrawer }} />
                    </Drawer>
                ) : (
                    <AdminSidebar />
                )}
                <div className={classes.content}>
                    <Suspense fallback={<Holding {...{ spinner: true }} />}>
                        <Outlet />
                    </Suspense>
                </div>
            </div>
            {modal}
        </div>
    );
};

const useStyles = makeStyles<{ isMobile: boolean; smallScreen: boolean }>()((
    theme: Theme,
    { isMobile, smallScreen }
) => {
    return {
        root: {
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            height: '100%',
            width: '100%',
        },
        appBar: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            height: 48,
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
            paddingLeft: smallScreen || isMobile ? theme.spacing(1) : theme.spacing(2),
            width: '100%',
        },
        breadcrumbs: {
            display: 'flex',
            justifyContent: 'left',
            width: '100%',
            alignItems: 'center',
            marginLeft: smallScreen || isMobile ? 0 : theme.spacing(20),
        },
        tools: {
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            paddingRight: theme.spacing(3),
        },
        main: {
            display: 'flex',
            height: `calc(100% - ${theme.spacing(8)})`,
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            // flex: 1,
            width: '100%',
            height: '100%',
            overflowX: 'hidden',
            overflowY: 'auto',
            transition: `max-height 300ms ease-in-out`,
            '&::-webkit-scrollbar': {
                width: 8,
            },
            scrollbarWidth: 'thin',
        },
    };
});

export default Admin;
