import { Link as RouterLink } from 'react-router-dom';
import {
    Avatar,
    Collapse,
    IconButton,
    Link,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Typography,
} from '@mui/material';
import { useLocation } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import projectAvatar from '@/images/project.png';
import { AddTask, ExpandLess, ExpandMore, FolderCopy, Group, ViewInAr } from '@mui/icons-material';
import { FC } from 'react';
import { useWorkspace } from '@/contexts/WorkspaceContext';

type ProjectSidebarProps = {
    project: Project;
    forceOpen: boolean;
    expandedId?: string;
    toggleExpanded: (id: string) => void;
    toggleDrawer?: () => void;
};

const ProjectSidebar: FC<ProjectSidebarProps> = ({ project, forceOpen, expandedId, toggleExpanded, toggleDrawer }) => {
    const { organization } = useWorkspace();
    const { classes } = useStyles();
    const location = useLocation();
    const projectPath = `/organizations/${project.organization_id}/projects/${project.id}`;
    const opened = forceOpen || expandedId === project.id;

    return (
        <>
            <ListItem
                sx={{ p: 0 }}
                secondaryAction={
                    <IconButton
                        onClick={() => toggleExpanded(project.id)}
                        size={'small'}
                        edge={'end'}
                        aria-label={'Toggle'}
                    >
                        {opened ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                }
            >
                <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={projectPath}>
                    <ListItemButton selected={forceOpen || location.pathname.includes(projectPath)} role={undefined}>
                        <ListItemAvatar sx={{ minWidth: (theme) => theme.spacing(4.5) }}>
                            <Avatar
                                sx={{ height: 25, width: 25 }}
                                alt={project.name}
                                src={project.avatar_route ?? projectAvatar}
                            >
                                {project.name}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>
                            <Typography sx={{ pr: 4 }} noWrap={true}>
                                {project.name}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </Link>
            </ListItem>
            <Collapse in={opened} timeout={'auto'}>
                <MenuList dense={true} className={classes.menuList}>
                    {organization?.supports_teams && (
                        <MenuItem selected={location.pathname === `${projectPath}/members`}>
                            <Link
                                onClick={toggleDrawer}
                                className={classes.link}
                                component={RouterLink}
                                to={`${projectPath}/members`}
                            >
                                <ListItemIcon>
                                    <Group />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant={'subtitle1'}>Members</Typography>
                                </ListItemText>
                            </Link>
                        </MenuItem>
                    )}
                    <MenuItem selected={location.pathname.includes(`${projectPath}/models`)}>
                        <Link
                            onClick={toggleDrawer}
                            className={classes.link}
                            component={RouterLink}
                            to={`${projectPath}/models`}
                        >
                            <ListItemIcon>
                                <ViewInAr />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Models</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                    <MenuItem selected={location.pathname.includes(`${projectPath}/folders`)}>
                        <Link
                            onClick={toggleDrawer}
                            className={classes.link}
                            component={RouterLink}
                            to={`${projectPath}/folders`}
                        >
                            <ListItemIcon>
                                <FolderCopy />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Folders</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                    <MenuItem selected={location.pathname.includes(`${projectPath}/reviews`)}>
                        <Link
                            onClick={toggleDrawer}
                            className={classes.link}
                            component={RouterLink}
                            to={`${projectPath}/reviews`}
                        >
                            <ListItemIcon>
                                <AddTask />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Reviews</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                </MenuList>
            </Collapse>
        </>
    );
};

const useStyles = makeStyles()((theme) => ({
    link: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    menuList: {
        paddingTop: 0,
        paddingLeft: theme.spacing(2.5),
    },
}));

export default ProjectSidebar;
