import { FC, useState } from 'react';
import notFoundImage from '@/images/image404.png';
import organizationAvatar from '@/images/organization.png';
import projectAvatar from '@/images/project.png';
import assetAvatar from '@/images/gears.png';
import collectionAvatar from '@/images/folder.png';
import userAvatar from '@/images/users.png';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

type ImageAvatarProps = {
    type?: AvatarType;
    avatar?: string | null;
    name?: string | null;
    size?: number;
    disableClickable?: boolean;
};

export type AvatarType = 'organization' | 'project' | 'asset' | 'collection' | 'user' | 'texture';

const ImageAvatar: FC<ImageAvatarProps> = ({ type, avatar, name, size, disableClickable }) => {
    const [open, setOpen] = useState<boolean>(false);

    const _name = name ?? type ?? 'Page';

    const getAvatar = (): string | null => {
        if (avatar === undefined || type === undefined) {
            return null;
        }

        if (avatar) {
            return avatar;
        }

        let defaultAvatar = notFoundImage;

        switch (type) {
            case 'organization':
                defaultAvatar = organizationAvatar;
                break;
            case 'project':
                defaultAvatar = projectAvatar;
                break;
            case 'asset':
                defaultAvatar = assetAvatar;
                break;
            case 'collection':
                defaultAvatar = collectionAvatar;
                break;
            case 'user':
                defaultAvatar = userAvatar;
                break;
        }

        return defaultAvatar;
    };

    const _avatar = getAvatar();

    if (!_avatar) {
        return null;
    }

    return (
        <>
            <Avatar
                onClick={disableClickable ? undefined : () => setOpen(true)}
                sx={{
                    height: size ?? 30,
                    width: size ?? 30,
                    ...(!disableClickable && {
                        cursor: 'pointer',
                        '&:hover': {
                            boxShadow: (theme) => `0 0 5px 2px ${theme.palette.primary.main}`,
                        },
                    }),
                }}
                src={_avatar}
                alt={`${_name}'s Avatar`}
            />
            <Dialog
                PaperProps={{
                    elevation: 0,
                }}
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
                onClose={() => setOpen(false)}
            >
                {type !== 'texture' && <DialogTitle>{`${_name}'s Avatar`}</DialogTitle>}
                <DialogContent dividers={true}>
                    <img
                        style={{
                            width: '100%',
                            height: 'auto',
                        }}
                        alt={'avatar'}
                        src={_avatar}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} color={'inherit'} onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ImageAvatar;
