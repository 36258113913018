type DateUtils = {
    toLocaleDateString: (date: string) => string;
};

const useDateUtils = (): DateUtils => {
    const toLocaleDateString = (date: string) =>
        new Date(date).toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        });

    return { toLocaleDateString };
};

export default useDateUtils;
