import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Badge, Divider, Link, ListItemIcon, ListItemText, MenuItem, MenuList, Stack, Typography } from '@mui/material';
import {
    AdminPanelSettings,
    Domain,
    FactCheck,
    FolderCopy,
    Group,
    Groups,
    Home,
    LiveHelp,
    ManageAccounts,
    Notifications,
    PhotoLibrary,
    QuestionAnswer,
    Security,
    ViewInAr,
    VolumeOff,
    VolumeUp,
} from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import UserStorageBar from '@/Users/UserStorageBar';
import UserSubscriptionModal from '@/Billing/User/UserSubscriptionModal';
import { toggleDarkMode, toggleAlertSounds } from '@/redux/reducers/userSlice';
import { useAppDispatch } from '@/redux/hooks';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import LogoutButton from '@/Layout/Sidebar/LogoutButton';
import { FC } from 'react';

type UserSidebarProps = {
    user: User;
    darkMode: boolean;
    alertSounds: boolean;
    toggleDrawer?: () => void;
};

const UserSidebar: FC<UserSidebarProps> = ({ user, darkMode, alertSounds, toggleDrawer }) => {
    const { classes } = useStyles();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const toggleDarkModeHandler = () => {
        dispatch(toggleDarkMode());
    };

    const toggleAlertSoundsHandler = () => {
        dispatch(toggleAlertSounds());
    };

    return (
        <>
            <div className={classes.navContainer}>
                <MenuList className={classes.menuList}>
                    <MenuItem className={classes.topItem} selected={location.pathname === '/home'}>
                        <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/home'}>
                            <ListItemIcon>
                                <Home />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Dashboard</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                    <MenuItem selected={location.pathname === `/home/organizations`}>
                        <Link
                            onClick={toggleDrawer}
                            className={classes.link}
                            component={RouterLink}
                            to={'/home/organizations'}
                        >
                            <ListItemIcon>
                                <Domain />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Organizations</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                    <MenuItem selected={location.pathname.includes('/home/models')}>
                        <Link
                            onClick={toggleDrawer}
                            className={classes.link}
                            component={RouterLink}
                            to={'/home/models'}
                        >
                            <ListItemIcon>
                                <ViewInAr />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Models</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                    <MenuItem selected={['/home/images', '/home/ai-images'].includes(location.pathname)}>
                        <Link
                            onClick={toggleDrawer}
                            className={classes.link}
                            component={RouterLink}
                            to={'/home/images'}
                        >
                            <ListItemIcon>
                                <PhotoLibrary />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Images</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                    <MenuItem selected={location.pathname.includes('/home/folders')}>
                        <Link
                            onClick={toggleDrawer}
                            className={classes.link}
                            component={RouterLink}
                            to={'/home/folders'}
                        >
                            <ListItemIcon>
                                <FolderCopy />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Folders</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                    <MenuItem selected={location.pathname === '/home/notifications'}>
                        <Link
                            onClick={toggleDrawer}
                            className={classes.link}
                            component={RouterLink}
                            to={'/home/notifications'}
                        >
                            <ListItemIcon>
                                <Badge color={'secondary'} badgeContent={user.unread_notifications_count} max={99}>
                                    <Notifications />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Notifications</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                    <MenuItem selected={location.pathname === '/home/shared/models'}>
                        <Link
                            onClick={toggleDrawer}
                            className={classes.link}
                            component={RouterLink}
                            to={'/home/shared/models'}
                        >
                            <ListItemIcon>
                                <Group />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Shared With Me</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                    <MenuItem selected={location.pathname.includes('/community')}>
                        <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/community'}>
                            <ListItemIcon>
                                <Groups />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Community</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                    <MenuItem selected={location.pathname === '/home/tutorials'}>
                        <Link
                            onClick={toggleDrawer}
                            className={classes.link}
                            component={RouterLink}
                            to={'/home/tutorials'}
                        >
                            <ListItemIcon>
                                <LiveHelp />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Tutorials</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                </MenuList>
                <Divider variant={'middle'} />
                <Stack spacing={1} className={classes.storage}>
                    <UserStorageBar />
                    {user.is_verified && <UserSubscriptionModal {...{ subscription: user.current_subscription }} />}
                </Stack>
                <Divider sx={{ mb: 1 }} variant={'middle'} />
                <MenuList className={classes.menuList}>
                    <MenuItem selected={location.pathname.includes('/home/account')}>
                        <Link
                            onClick={toggleDrawer}
                            className={classes.link}
                            component={RouterLink}
                            to={'/home/account'}
                        >
                            <ListItemIcon>
                                <ManageAccounts />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Your Account</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                    {user.is_admin && (
                        <MenuItem selected={location.pathname.includes('/admin')}>
                            <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/admin'}>
                                <ListItemIcon>
                                    <AdminPanelSettings />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant={'subtitle1'}>Admin</Typography>
                                </ListItemText>
                            </Link>
                        </MenuItem>
                    )}
                    <MenuItem onClick={toggleDarkModeHandler}>
                        <ListItemIcon>{darkMode ? <DarkModeIcon /> : <LightModeIcon />}</ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>Theme</Typography>
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={toggleAlertSoundsHandler}>
                        <ListItemIcon>{alertSounds ? <VolumeUp /> : <VolumeOff />}</ListItemIcon>
                        <ListItemText>
                            <Typography variant={'subtitle1'}>Alert Sounds</Typography>
                        </ListItemText>
                    </MenuItem>
                    <Divider component={'li'} variant={'middle'} />
                    <MenuItem selected={location.pathname === '/terms'}>
                        <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/terms'}>
                            <ListItemIcon>
                                <FactCheck />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Terms</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                    <MenuItem selected={location.pathname === '/privacy'}>
                        <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/privacy'}>
                            <ListItemIcon>
                                <Security />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Privacy</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                    <MenuItem selected={location.pathname === '/contact'}>
                        <Link onClick={toggleDrawer} className={classes.link} component={RouterLink} to={'/contact'}>
                            <ListItemIcon>
                                <QuestionAnswer />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant={'subtitle1'}>Contact Us</Typography>
                            </ListItemText>
                        </Link>
                    </MenuItem>
                </MenuList>
            </div>
            <div style={{ display: 'flex', flex: 1 }}></div>
            <Divider variant={'middle'} />
            <LogoutButton />
        </>
    );
};

const useStyles = makeStyles()((theme) => ({
    link: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    navContainer: {
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 0,
            display: 'none',
        },
        scrollbarWidth: 'none',
    },
    menuList: {
        paddingTop: 0,
        '& .MuiMenuItem-root': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
    },
    storage: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    topItem: {
        borderTopRightRadius: theme.spacing(1),
    },
}));

export default UserSidebar;
